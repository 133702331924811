import { render, staticRenderFns } from "./SingleVehicle.vue?vue&type=template&id=b39856ec&scoped=true&"
import script from "./SingleVehicle.vue?vue&type=script&lang=js&"
export * from "./SingleVehicle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b39856ec",
  null
  
)

export default component.exports